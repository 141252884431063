import React, { useState } from 'react';
import './css/contact.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function Contact() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);


    const sendEmail = () => {
        if (!name || !phone || !email || !message) {
            setPopupMessage('Tous les champs doivent être remplis!');
            setIsPopupVisible(true);
            return;
        }

        const subject = "Nouveau message de " + name;
        const body = `Nom: ${name}\nTéléphone: ${phone}\nEmail: ${email}\nMessage: ${message}`;

        window.open(`mailto:destinataire@example.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
        
        setPopupMessage('Votre message a été envoyé avec succès!');
        setIsPopupVisible(true);
        
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
    };

    const closePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <div className="contact-container">
            <div className="map-section">
                <iframe
                    title="Location Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.890626392775!2d2.2944813160718515!3d48.85837007928792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fddf57d5f9f%3A0x8f4db0975a8d4d8c!2sEiffel%20Tower!5e0!3m2!1sen!2sus!4v1615998377744!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>

            <div className="form-info-section">
                <div className="contact-form">
                    <h2>{t("Name: ")} </h2>
                    <input
                        type="text"
                        placeholder={t("Enter your name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <h2>{t("Phone: ")}</h2>
                    <input
                        type="text"
                        placeholder={t("Enter your phone number")}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <h2>{t("Email: ")}</h2>
                    <input
                        type="email"
                        placeholder={t("Enter your email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <h2>{t("Message: ")}</h2>
                    <textarea
                        placeholder={t("Enter your message")}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    <button className="submit-btn" onClick={sendEmail}>{t("Send")}</button>
                </div>

                <div className="contact-info">
                    <h2 className='contact_titre'>{t("Contact Us")}</h2>
                    <p>
                        {t("Contact Us , We are alwayse avaible to answer question and support you in your projects.")}
                        
                    </p>
                    <div className="info-item">
                        <i className="fas fa-map-marker-alt"></i>
                        <p>Le Lorem Ipsum est simplement du faux texte dans la composition et la mise en page</p>
                    </div>
                    <div className="info-item">
                        <i className="fas fa-phone-alt"></i>
                        <p>+212-57328729</p>
                    </div>
                </div>
            </div>

            {isPopupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button onClick={closePopup}>Fermer</button>
                    </div>
                </div>
            )}

           
        </div>
    );
}

export default Contact;
