import './css/about.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
export default function About() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);
    return (
        <>
            <img src='/images/about_img.jpg' alt='Travaux de construction publics' className='img_home_about' />

            <div className='section_about'>
                <div className='sous_section_qui'>
                    <h2 className='titre_qui'>
                        <img src='./images/qui.png' className='img_qui' alt='Logo de l’entreprise' />
                        {t('Who Are We?')}
                    </h2>

                    <p className='p_qui'>
                        {t("At MOUVE UP, a Moroccan limited liability company, we are committed to excelling in the industrial and civil sectors. Our strategy is based on innovation, creativity, and adapting to the specific needs of our clients. With our expertise, we work across various fields, from constructing complex infrastructures to project management, urban planning, and the rehabilitation of historic buildings. Our approach is grounded in attentive listening to our clients' expectations and anticipating their needs, ensuring tailor-made solutions. The synergy of our teams, driven by a shared desire to create and provide sustainable and effective solutions, is our greatest strength. With unwavering commitment from our leadership, we have established continuous improvement as a key driver of our growth. MOUVE UP is not just a construction company: we are a trusted partner, dedicated to the success of every project we undertake."
                        )}

                    </p>

                    <div className='div_cont'>
                        <div>
                            <h2 className='titre_qui'>{t("MOUVE UP's Mission")} </h2>
                            <p className='p_qui'>
                                {t("At MOUVE UP, our mission is to meet the needs of our clients by offering innovative construction and public works solutions tailored to each specific project. We are committed to ensuring exceptional quality at every stage of our work, with rigorous monitoring and flawless finishes. Client satisfaction is at the heart of our approach, driving us to meet deadlines while controlling costs. Moreover, our commitment to health, safety, and the environment (HSE) is paramount. We implement preventive practices to ensure safe and sustainable working conditions. By collaborating with a team of passionate professionals and using modern equipment, MOUVE UP strives to continuously improve its processes to deliver services that meet our clients' expectations, solidifying our position as a trusted partner in the construction industry.")}
                            </p>
                        </div>

                        <img src='./images/about.png' className='imag_about_qui' alt='Entreprise de Construction' />
                    </div>
                    <div className='section_travaux'>
                        <h2 className='titre_qui'>Notre secteur d’activité</h2>
                        <div className='list_travaux'>
                            <div className='travail'>
                                <h3>Travaux de démolition</h3>
                                <p>Démolition contrôlée et sécurisée de bâtiments et structures.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de terrassement courants et travaux préparatoires</h3>
                                <p>Mise à niveau des terrains pour la construction.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de terrassement spécialisés ou de grande masse</h3>
                                <p>Terrassements complexes pour les grands projets d’infrastructure.</p>
                            </div>
                            <div className='travail'>
                                <h3>Forages et sondages</h3>
                                <p>Exploration et évaluation des sols avant construction.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux d’installation électrique dans tous locaux</h3>
                                <p>Installation de systèmes électriques dans les bâtiments résidentiels, commerciaux et industriels.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux d’installation électrique sur la voie publique</h3>
                                <p>Électrification des espaces publics et infrastructures urbaines.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux d’installation d’eau et de gaz en tous locaux</h3>
                                <p>Pose de systèmes de plomberie et de gaz.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux d’installation d’équipements thermiques et de climatisation</h3>
                                <p>Installation de systèmes de chauffage et de climatisation pour un confort optimal.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux d’isolation</h3>
                                <p>Isolation thermique et acoustique pour un meilleur rendement énergétique.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de plâtrerie</h3>
                                <p>Finition et lissage des murs pour préparer la décoration intérieure.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de menuiserie bois et PVC</h3>
                                <p>Fabrication et installation de menuiseries sur mesure.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de menuiserie métallique et serrurerie</h3>
                                <p>Conception de structures métalliques pour une durabilité accrue.</p>
                            </div>
                            <div className='travail'>
                                <h3>Agencement de lieux de vente</h3>
                                <p>Aménagement sur mesure d’espaces commerciaux.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de revêtement des sols et des murs</h3>
                                <p>Pose de revêtements adaptés aux sols et murs pour un fini professionnel.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de peinture et vitrerie</h3>
                                <p>Peinture intérieure et extérieure ainsi que pose de vitres.</p>
                            </div>
                            <div className='travail'>
                                <h3>Autres travaux de finition</h3>
                                <p>Finitions générales pour livrer un projet prêt à l’usage.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de charpente</h3>
                                <p>Construction de charpentes pour tous types de bâtiments.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de couverture par éléments</h3>
                                <p>Pose de couvertures de toitures avec des matériaux adaptés.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de montage de structures métalliques</h3>
                                <p>Assemblage et montage de structures métalliques pour des projets industriels ou commerciaux.</p>
                            </div>
                            <div className='travail'>
                                <h3>Travaux de maçonnerie générale et gros œuvre de bâtiment</h3>
                                <p>Construction et renforcement de bâtiments résidentiels et industriels.</p>
                            </div>
                            <div className='travail'>
                                <h3>Autres travaux spécialisés de construction</h3>
                                <p>Interventions spécifiques pour répondre aux besoins techniques des projets.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
