
import React from 'react';
import './css/YouTubeChannel.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
const YouTubeChannel = () => {
  const { t, i18n } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);
  return (
    <div className="youtube-channel">
      <h2 className="youtube-title">
        <img src="./images/iconYou.png" alt="Image" className="iconYou" /> {t('Subscribe to Our YouTube Channel')}
      </h2>
      <p className="youtube-description">{t("Our YouTube channel features detailed videos showcasing all of our completed projects. You will find in-depth explanations of each step of the process, practical demonstrations, as well as tips and advice based on our experience.")}
      </p>
      <div className="youtube-video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/your-video-id"
          title="YouTube video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default YouTubeChannel;
