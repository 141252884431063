import './css/references.css';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { PrevArrow, NextArrow } from './CustomArrows';
import { useTranslation } from 'react-i18next';
const References = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
    }
}, [i18n]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const logos = [
    { src: './images/logo1.png', alt: 'CAT' },
    { src: './images/logo2.png', alt: 'CNB' },
    { src: './images/logo3.png', alt: 'ANP' },
    { src: './images/logo4.png', alt: 'XEP' },
    { src: './images/logo5.png', alt: 'XEP' },
    { src: './images/logo6.png', alt: 'XEP' },
    { src: './images/logo7.png', alt: 'XEP' },
    { src: './images/logo8.png', alt: 'XEP' },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <PrevArrow />,  
    nextArrow: <NextArrow />,  
  };

  return (
    <div className="references">
      <div className="references_titre">
        <img src="./images/chap.png" alt="Image" className="iconChap_references" />
        <h3 className="H3_references">{t("References")}</h3>
      </div>
      <div className="references_slider_div">
        {isMobile ? (
          <div className="references-grid">
            {logos.map((logo, index) => (
              <div key={index} className="logo-slide">
                <img src={logo.src} alt={logo.alt} className="logo" />
              </div>
            ))}
          </div>
        ) : (
          <Slider {...settings} className="references-slider">
            {logos.map((logo, index) => (
              <div key={index} className="logo-slide">
                <img src={logo.src} alt={logo.alt} className="logo" />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default References;
