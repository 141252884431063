import './App.css';
import Header from './component/header';
import Home from './component/home';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './component/contact';
import About from './component/about';
import Project from './component/projects';
import Services from './component/services';
import ServiceDetail from './component/ServiceDetail';
import Footer from './component/footer';
import ProjectDetail from './component/detailProjetct';

function App() {
  return (
    <Router>
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project" element={<Project />} />
          <Route path="/services" element={<Services />} />
          <Route path="/servicesDetail/:id" element={<ServiceDetail />} />
          <Route path="/detailProjetct/:id" element={<ProjectDetail />} />
          
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}


export default App;
