import React from 'react';


const PrevArrow = ({ onClick }) => {
  return (
    <div className="slider-arrow prev-arrow" onClick={onClick}>
      &#10094; 
    </div>
  );
};


const NextArrow = ({ onClick }) => {
  return (
    <div className="slider-arrow next-arrow" onClick={onClick}>
      &#10095; 
    </div>
  );
};

export { PrevArrow, NextArrow };
