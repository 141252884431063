import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './css/detailProjetct.css'; 
import { useTranslation } from 'react-i18next';

const ProjectDetail = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    const project = {
        id: 1,
        image: '/images/maison.jpeg',
        title: 'Titre de projet',
        description: 'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.',
        location: 'Le Lorem Ipsum est simplement',
        date: '12/12/2023',
        category: 'Le Lorem Ipsum est simplement'
    };

    if (parseInt(id) !== project.id) {
        return <p>Project not found.</p>;
    }

    return (
        <div className="project-detail-container container">
            <div className="row">
                <div className="col-md-6">
                    <img src={project.image} alt={project.title} className="project-detail-image img-fluid" />
                    
                </div>
                <div className="col-md-6">
                    <h2 className="project-detail-title">{project.title}</h2>
                    <p className="project-detail-description">{project.description}</p>
                    <div className="project-detail-info">
                        <p><strong>{t("Location")}:</strong> {project.location}</p>
                        <p><strong>{t("Date")}:</strong> {project.date}</p>
                        <p><strong>{t("Category")}:</strong> {project.category}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetail;
