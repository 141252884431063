import React from 'react';
import './css/footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-section logo-section">
          <img src='./images/mouveup.png' className='logo_mouveup_footer' alt='MOUVEUP' />
         
        </div>

        <div className="footer-section">
          <ul>
            <Link className='Link' to={`/servicesDetail/${2}`}><li><i className="fas fa-arrow-right"></i>{t('service home 6 titre')} </li></Link>
            <Link className='Link' to={`/servicesDetail/${3}`}><li><i className="fas fa-arrow-right"></i>{t('service home 2 titre')} </li></Link>
            <Link className='Link' to={`/servicesDetail/${4}`}><li><i className="fas fa-arrow-right"></i>{t('service home 3 titre')} </li></Link>
            <Link className='Link' to={`/servicesDetail/${5}`}><li><i className="fas fa-arrow-right"></i>{t('service home 4 titre')} </li></Link>
            <Link className='Link' to={`/servicesDetail/${1}`}><li><i className="fas fa-arrow-right"></i>{t('service home 5 titre')} </li></Link>
            <Link className='Link' to={`/servicesDetail/${6}`}><li><i className="fas fa-arrow-right"></i>{t('service home 7 titre')} </li></Link>
            <Link className='Link' to={`/servicesDetail/${7}`}><li><i className="fas fa-arrow-right"></i>{t('service home 8 titre')} </li></Link>
          </ul>
        </div>

        <div className="footer-section">
          <ul>
            <Link className='Link' to={'/'}><li><i className="fas fa-arrow-right"></i> {t('Home')} </li></Link>
            <Link className='Link' to={'/about'}><li><i className="fas fa-arrow-right"></i>{t('About')} </li></Link>
            <Link className='Link' to={'/services'}><li><i className="fas fa-arrow-right"></i> {t('Project')} </li></Link>
            <Link className='Link' to={'/services'}><li><i className="fas fa-arrow-right"></i> {t('Services')} </li></Link>
            <Link className='Link' to={'/services'}><li><i className="fas fa-arrow-right"></i> {t('Contact')} </li></Link>
          </ul>
        </div>

        <div className="footer-section">
          <h3>{t('Contact Us')}</h3>
          <p><strong>Adresse :</strong> Le Lorem Ipsum est simplement du faux texte employé</p>
          <p><strong>Email :</strong> entre@gmail.com</p>
          <p><strong>{t('Phone: ')}</strong> 05 7232388</p>
        </div>
      </div>

      <div className="footer-bottom">
        <p> © 2024 . Created by ANTAR Ikram</p>
        <div className="social-icons">
          <a href="/"><i className="fab fa-facebook"></i></a>
          <a href="/"><i className="fab fa-twitter"></i></a>
          <a href="/"><i className="fab fa-instagram"></i></a>
          <a href="/"><i className="fab fa-youtube"></i></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
