import React from 'react';
import './css/services.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


function Services() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);


    const services=[
        {
                    id: 1,
                    title: "titreG1",
                    descriptionG: "descriptionG1",
                    image: "./images/construction_batiment.png",
                    subServices: [
                        {
                            title: "subservicesG1_s1",
                            description: "descriptionG1_subserv1",
                            image: "/images/constru.png",
                            
                        },
        {
                            title: "subservicesG1_s2",
                            description: "descriptionG1_subserv2",
                            image: "/images/construct_rout.png",
                        },
        
                        
                    ]
                },
        {
                    id: 2,
                    title: "titreG2",
                    descriptionG: "descriptionG2",
                    image: "./images/RestaurationBTH.png",
                    subServices: [
                        {
                            title: "subservicesG2_s1",
                            description: "descriptionG2_subserv1",
                            image: "/images/restoration_mosquer.png",
                        },
        {
                            title: "subservicesG2_s2",
                            description: "descriptionG2_subserv2",
                            image: "/images/reno_batiment.png",
                        },
        
                        
                    ]
                },
        {
                    id: 3,
                    title: "titreG3",
                    descriptionG: "descriptionG3",
                    image: "./images/Resine.png",
                    subServices: [
                        {
                            title: "subservicesG3_s1",
                            description: "descriptionG3_subserv1",
                            image: "/images/Resine.png",
                        },
        {
                            title: "subservicesG3_s2",
                            description: "descriptionG3_subserv2",
                            image: "/images/Revetements.png",
                        },
        
                        
                    ]
                },
        {
                    id: 4,
                    title: "titreG4",
                    descriptionG: "descriptionG4",
                    image: "./images/isolation.png",
                    subServices: [
                        {
                            title: "subservicesG4_s1",
                            description: "descriptionG4_subserv1",
                            image: "/images/isolation.png",
                        },
        {
                            title: "subservicesG4_s2",
                            description: "descriptionG4_subserv2",
                            image: "/images/isolation_murs.png",
                        },
        
                        
                    ]
                },
        {
                    id: 5,
                    title: "titreG5",
                    descriptionG: "descriptionG5",
                    image: "./images/fin_inter.png",
                    subServices: [
                        {
                            title: "subservicesG5_s1",
                            description: "descriptionG5_subserv1",
                            image: "/images/peintres.png",
                        },
        {
                            title: "subservicesG5_s2",
                            description: "descriptionG5_subserv2",
                            image: "/images/platrier2.png",
                        },
        
                        
                    ]
                },
        {
                    id: 6,
                    title: "titreG6",
                    descriptionG: "descriptionG6",
                    image: "./images/interieur.png",
                    subServices: [
                        {
                            title: "subservicesG6_s1",
                            description: "descriptionG6_subserv1",
                            image: "/images/interieur.png",
                        },
                        
                    ]
                },
        {
                    id: 7,
                    title: "titreG7",
                    descriptionG: "descriptionG7",
                    image: "./images/durabiliter_eco.png",
                    subServices: [
                        {
                            title: "subservicesG7_s1",
                            description: "descriptionG7_subserv1",
                            image: "/images/pompe_solaire.png",
                        },
        {
                            title: "subservicesG7_s2",
                            description: "descriptionG7_subserv2",
                            image: "/images/filtration_deau.png",
                        },
        
        {
                            title: "subservicesG7_s3",
                            description: "descriptionG7_subserv3",
                            image: "/images/micro-irrigation.png",
                        },
        
        
                        
                    ]
                },
        
        
        
        
        
        
        
        ]

    return (
        <div className="services-container">
            {services.map((service, index) => (
                <div
                    key={service.id}
                    className={`service-item ${index % 2 === 0 ? 'image-right' : 'image-left'}`}
                >
                    <div className="service-content">
                        <h2>{t(service.title)}</h2>
                        <p>{t(service.descriptionG)}</p>
                        <Link to={`/servicesDetail/${service.id}`}>
                            <button className="service-link">{t('View more')}</button>
                        </Link>


                    </div>
                    <div
                        className="service-image"
                        style={{ backgroundImage: `url(${service.image})` }}
                    ></div>
                </div>
            ))}
        </div>
    );
}

export default Services;
