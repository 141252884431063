import React from 'react';
import './css/project.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Project = () => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);
    const projects = [
        {
            id: 1,
            image: './images/maison.jpeg',
            title: 'Titre de projet 1',
            description: 'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.',
        },
        {
            id: 2,
            image:'./images/renouvation.jpg',
            title: 'Titre de projet 2',
            description: 'Un autre projet avec des détails sur les caractéristiques et les objectifs.',
        },
        {
            id: 3,
            image: './images/maison3.jpeg',
            title: 'Titre de projet 3',
            description: 'Description du projet 3 pour les détails sur les caractéristiques et les objectifs.',
        },{
            id: 4,
            image: './images/hassan2.jfif',
            title: 'Titre de projet 3',
            description: 'Description du projet 3 pour les détails sur les caractéristiques et les objectifs.',
        },
        {
            id: 5,
            image: './images/maison.jpeg',
            title: 'Titre de projet 1',
            description: 'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page.',
        },
        {
            id: 6,
            image:'./images/renouvation.jpg',
            title: 'Titre de projet 2',
            description: 'Un autre projet avec des détails sur les caractéristiques et les objectifs.',
        },
        {
            id: 7,
            image: './images/maison3.jpeg',
            title: 'Titre de projet 3',
            description: 'Description du projet 3 pour les détails sur les caractéristiques et les objectifs.',
        },{
            id: 8,
            image: './images/hassan2.jfif',
            title: 'Titre de projet 3',
            description: 'Description du projet 3 pour les détails sur les caractéristiques et les objectifs.',
        },
    ];

    return (
        <div className="projects-container">
            <h2 className="projects-title">{t("Our Projects")} </h2>
            
            <p className="projects-intro">{t("Discover our achievements, which showcase our commitment to quality, durability, and innovation in every detail.")}
                </p>
            <div className="projects-grid">
                {projects.map((project) => (
                    <div key={project.id} className="project-card">
                        <img src={project.image} alt={project.title} className="project-image" />
                        <div className="project-overlay">
                            <h3 className="project-title">{project.title}</h3>
                            <p className="project-description">{project.description}</p>
                            <Link to={`/detailProjetct/${project.id}`} className="project-link">voir plus + </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Project;
