
import Slider from 'react-slick';
import './css/home.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const images = [
  { id: 1, src: './images/img3.jpg' ,description:"Welcome to our site, where together we build a solid and sustainable future."},
  { id: 2, src: './images/img2.jpg' ,description:"Explore our projects and discover how we are shaping a better world, one step at a time"},
  { id: 3, src: './images/img1.jpg' ,description:"Join us on our journey towards creating sustainable solutions for a brighter future."},
];

const ImageCarousel = () => {
  const { t ,i18n} = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
    }
}, [i18n]);
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 60000,
    lazyLoad: 'ondemand',
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {images.map((image) => (
        <div key={image.id} className="carousel-image">
          <img src={image.src} alt={image.title} className="full-screen-image" />
          <div className="carousel-text">
            <div className="banner-content">
              <div className="logo-section1">
              <img src='./images/mouveup.png' className='logo_mouveup_home' alt='MOUVEUP' />
                <div className="vertical-line"></div>
              </div>
              <div className="text-section">
                <h1>{t(image.description)}</h1>
                <div className="button-group">
                <Link to={'/services'}>  <button className="dark-brown">{t('Services')}</button></Link>
                <Link to={'/about'}>  <button className="light-brown">{t('Learn More')}</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ImageCarousel;
